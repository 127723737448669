const pageHead =
{
  namespaced: true,

  state:
  {
    title: null,
    description: null

  },

  getters:
  {
    getTitle: state =>
    {
      return state.title;
    },
    getDescription: state =>
    {
      return state.description;
    }
  },

  mutations:
  {
    setTitle: (state, payload) =>
    {
      state.title = (payload) ? `${payload} - Roote Admin` : "Roote Admin";
    },

    setDescription: (state, payload) =>
    {
      state.description = payload;
    }

  }
};

export default pageHead;