<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default
{
  name: 'App',

  components:
  {

  },

  data: () => ({
    //
  }),
};
</script>

<style>
  html
  {
    overflow-y: auto;
  }

  body
  {
    margin: 0 auto;
    padding: 0;
  }

  #app
  {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    display: flex;
  }
</style>
