<template>
  <div style="min-height:100vh;">

    <Topbar v-if="!$route.meta.isPublic"/>

    <Sidebar v-if="!$route.meta.isPublic" />

    <PageHeadHandler />

    <Toast />

    <v-main class="bg-main" fill-height style="min-height: 100vh;" >

      <!--Error404Integrated v-if="$store.getters['display/isError']"/-->

      <router-view :key="$route.fullPath" />
    </v-main>

  </div>
</template>

<script>

  export default
  {
    name: "AppLayout",

    components:
    {
      PageHeadHandler: () => import( "@/components/PageHeadHandler"),
      Sidebar: () => import("@/components/Bars/Sidebar"),
      Topbar: () => import("@/components/Bars/Topbar"),
      Toast: () => import("@/components/Toast")
    },

    mounted()
    {
      window.scrollTo(0, 0)
    },
  }
</script>

<style scoped>
  .bg-main
  {
    background-color: #EEEEEE;
    height: 100%;
  }
  .word-wrap
  {
    word-break: break-word;
  }

  .main_card-div
  {
    height: 150px;
    margin-top: 20px;
  }

  .detail-text-label
  {
    color: rgba(0, 0, 0, 0.55);
  }

  .detail-text-value
  {
    color: rgba(0, 0, 0, 0.80);
  }
</style>