import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './store'

import VueSession from 'vue-session';
import {HTTP} from "./plugins/http-common";
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import vueMoment from 'vue-moment';
import moment from 'moment';
require('moment/locale/fr');

import VueClipboard from 'vue-clipboard2'

Vue.use(VueSession, {persist: true});
Vue.use(vueMoment, {moment});

Vue.filter("truncate", function(text, length = 40, clamp = "...") {
  if (text)
    return `${text.slice(0, length)} ${(length < text.length ? clamp : '')}`;
  return "";
});

Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.prototype.$http = HTTP;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueSession,
  render: h => h(App)
}).$mount('#app');
