const alert =
{
    namespaced: true,

    state:
    {
      toggle: false,
      text: "",
      color: "",
      icon: "far fa-exclamation-triangle",
      dismissible: true
    },
    mutations:
    {
      showError: function (state, text)
      {
        state.text = text;
        state.color = "error";
        state.icon = "far fa-exclamation-triangle";
        state.toggle = true;
      },

      showSuccess: function (state, text)
      {
        state.text = text;
        state.color = "success";
        state.icon = "far fa-check-circle";
        state.toggle = true;
      },

      hideAlert: function (state)
      {
        state.toggle = false;
      }
    }
};

export default alert;