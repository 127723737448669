import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../plugins/router';

import alert from './alert';
import data from './data';
import toast from './toast';
import pageHead from './pageHead';
import sidebar from "./sidebar";

Vue.use(Vuex);

export default new Vuex.Store(
{
  modules:
  {
    alert,
    data,
    toast,
    pageHead,
    sidebar
  },

  state:
  {
  },

  mutations:
  {
  },

  actions:
  {
    logout()
    {
      this._vm.$session.destroy();
      router.push({name: "login"});
    }
  }
})