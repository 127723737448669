import axios from "axios"


const axiosInstance = axios.create(
{
  baseURL: process.env.VUE_APP_API_URL
});

axiosInstance.interceptors.response.use(
  res => {return res;},
  err =>
  {
    if (!err.response.status)
    {
      let customErr = {...err};
      customErr['response']['status'] = 500;
      return Promise.reject(customErr);
    }

    if (err.response.status === 401 && err.response.data)
    {
      const arrTypes = ["EXPIRED_TOKEN", "BAD_TOKEN", "INVALID_SESSION"];

      (arrTypes.includes(err.response.data.type));{
        window.location.href= "/auth/login";
      }
    }

    return Promise.reject(err);
  });

export const HTTP = axiosInstance;