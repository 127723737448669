const data =
{
  namespaced: true,

  state:
  {
    module: null,

    pagination:
    {
      page: null,
      rowsPerPage: 0
    }

  },

  getters:
  {
    getPagination: state =>
    {
      return state.pagination;
    },

    getModule: state =>
    {
      return state.module;
    }
  },

  mutations:
  {
    setPagination: function (state, payload)
    {
      state.pagination = {...payload};
    },

    setModule: function(state, payload)
    {
      state.module = payload;
    }
  }
};

export default data;