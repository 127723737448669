import Vue from 'vue'
import VueRouter from 'vue-router'

import AppLayout from '../views/AppLayout';

Vue.use(VueRouter);

const routes =
[
  {
    path: "/auth/login",
    name: "login",
    component: () => import('../views/Auth/Login.vue'),
    meta: { isPublic: true }
  },

  {
    path: '/',
    name: 'layout',
    redirect: "dashboard",
    component: AppLayout,
    children:
    [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import('../views/Dashboard/Index.vue')
      },

      {
        path: "subscriptions",
        name: "subscriptions",
        component: () => import("../views/Subscriptions/All.vue")
      },
      {
        path: "subscriptions/:id",
        name: "subscriptions/id",
        component: () => import("../views/Subscriptions/One")
      },

      {
        path: "orders",
        name: "orders",
        component: () => import("../views/Orders/All")
      },
      {
        path: "developers-users",
        name: "developers-users",
        component: () => import("../views/DevelopersUsers/All")
      },
      {
        path: "developers-users/:id",
        name: "developers-users/id",
        component: () => import("../views/DevelopersUsers/One")
      }
    ]
  }
];

const router = new VueRouter(
{
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) =>
{
  if (!to.matched.some(record => record.meta.isPublic) && !router.app.$session.exists())
  {
    next({name: "login"});
  }
  else
  {
    next();
  }
});


export default router
