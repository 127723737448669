function initialState()
{
  return {
    toggle: true
  }
}

const sidebar =
{
  namespaced: true,

  state: initialState,

  getters:
  {
    getToggle: state =>
    {
      return state.toggle;
    }
  },
  mutations:
  {
    setToggle(state, toggle)
    {
      state.toggle = toggle;
    },

    toggleSidebar(state)
    {
      state.toggle = !state.toggle;
    },

    reset (state)
    {
      const s = initialState();
      Object.keys(s).forEach(key =>
      {
        state[key] = s[key]
      })
    }
  }
};

export default sidebar;