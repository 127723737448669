import Vue from 'vue'
import VueI18n from 'vue-i18n'



Vue.use(VueI18n);

const messages =
{
  fr:
  {
    auth:   require('../translations/auth/fr.json'),
    common: require('../translations/common/fr.json'),
    dashboard: require('../translations/dashboard/fr.json'),
    subscriptions: require('../translations/subscriptions/fr.json'),
    orders: require('../translations/orders/fr.json'),
    "developers": require('../translations/developers/fr.json')
  }
};

export default new VueI18n(
{
  locale: "fr",
  messages: messages,
  silentTranslationWarn: true
});