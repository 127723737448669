const toast =
{
  namespaced: true,

  state:
  {
    show: false,
    color: "",
    timeout: 6000,
    mode: "",
    text: ""

  },

  getters:
  {
    isVisible: state =>
    {
      return state.show;
    },
    getColor: state =>
    {
      return state.color;
    },
    getTimeout : state =>
    {
      return state.timeout;
    },
    getMode: state =>
    {
      return state.mode;
    },
    getText: state =>
    {
      return state.text;
    }
  },

  mutations:
  {
    showSuccess: (state, payload) =>
    {
      state.color = "success";
      state.text = payload;
      state.show = true;
    },

    showError: (state, payload) =>
    {
      state.color = "error";
      state.text = payload;
      state.show = true;
    },

    setVisible: (state, payload) =>
    {
      state.show = payload;
    },

    hideToast: (state) =>
    {
      state.show = false;
    }
  }
};

export default toast;